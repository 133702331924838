import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from "gatsby"
import styled from 'styled-components'
import { transparentize, readableColor } from 'polished'
import { config, useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import { Box, AnimatedBox, Button, Input, TextArea, Form, Label } from '../elements'
import SEO from '../components/SEO'

const About = (props) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`

  const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
`

/*Image Description*/
const DImg = styled(Img)`
  max-height: calc(90%);
  margin: 1em;
  margin-top: 0.5em;
  max-width: 500px;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-column: 1;
  }
`

const Fdiv = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-auto-flow: row dense;
  grid-gap: 2em;
  padding: 1.2em;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-gap: .2em;
  }
`


  return (
    <Layout>
      <SEO title="About | Marcellin" desc="Hi. I'm Marcellin Piguet! You can also visit my instagram account." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 6, 6]}>
        
          
                   
            <Fdiv style={{ textAlign: 'left' }} py={4} px={[6, 6, 8, 10]}>
              <div>
              <h1>Hi. I'm Marcellin!</h1>
                <p>I always have been excited about lights and nature. <br/>My goal is to share with you the endless beauty of the places where I love to recharge my batteries. 🔋</p>
                <p>My childhood in Leysin, a ski resort in Swiss Alps, gave me the love of nature. In 2006, at 22 years old I had worked in a mountain pasture called "chalet d'Isenau". I had to gather the cows each morning at the sunrise to milk them and help to make cheese.</p> 
                <p>I haven't missed the sunset in two months. It has changed me.</p>
                <p>Not everybody wants to sleep on the top of the mountains and wakes up before the sun. It's cold in winter, and the storm is never far in summer. To give access to the infinite beauty of nature is essential to me. It's something that everybody should see, even comfortably sitting on a sofa 😁</p>
                <p>I hope you will enjoy it.<br/>Marcellin</p>

                <PBox style={{ textAlign: 'center' }} py={4} px={[6, 6, 8, 10]}>
            <Link to="/projects">
              <PButton color={"#f1f2f3"} py={4} px={8}>
                Discover my projects 🌄
              </PButton>
            </Link>
            <br/><br/>
            <a href="https://nomadict.org/marcellin-piguet-marcellinpiguet-photographer-based-in-switzerland" target="_blank" >
              <PButton color={"#000000"} py={4} px={8}>
                My story on Nomadict ➡
              </PButton></a>
        </PBox>
              </div>
            <DImg fluid={props.data.imageTwo.childImageSharp.fluid} />
            </Fdiv>
        
        
        
        
      </AnimatedBox>
    </Layout>
  )
}

export default About

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 700, maxHeight: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "form_img.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "marcellin.jpg" }) {
      ...fluidImage
    }
  }
`